/* Header CSS goes here. */

.ui.menu {
  background-color: #1A252D;
}
.ui.menu.header {
  padding: 1rem;
}

.content {
  background-color: #131a21;
  padding: 1rem;
}

.ui.header {
  font-family: 'Furore', 'sans-serif';
  font-size: 1.2rem;
}

.ui.input > input {
  font-family: 'Furore', 'sans-serif';
}

.ui.stackable.five.item.missionMenu.menu {
  padding-right: 0.6em !important;
}

.ui.list .list > .item .header, .ui.list > .item .header {
  font-family: 'Furore', 'sans-serif';
  font-weight: normal;
}

.ui.list .list > .item > .content, .ui.list > .item > .content {
  background-color: transparent;
}

.ui.list .list > .item a, .ui.list > .item a {
  color: black;
}

.ui.list .list > .item a:hover, .ui.list > .item a:hover {
  color: black !important;
}

.ui.cards > .card {
  height: 100%;
}

.ui.cards > .yellow.card, .ui.yellow.card, .ui.yellow.cards > .card {
  border-top: 2px solid #FBBD08;
  box-shadow: none;
}
.ui.cards > .yellow.card:hover, .ui.yellow.card:hover, .ui.yellow.cards > .card:hover {
  border-top: 2px solid #FBBD08;
  box-shadow: none;
}

.ui.cards > .green.card, .ui.green.card, .ui.green.cards > .card {
  border-top: 2px solid #21BA45;
  box-shadow: none;
}
.ui.cards > .green.card:hover, .ui.green.card:hover, .ui.green.cards > .card:hover {
  border-top: 2px solid #21BA45;
  box-shadow: none;
}

.ui.cards > .red.card, .ui.red.card, .ui.red.cards > .card {
  border-top: 2px solid #DB2828;
  box-shadow: none;
}
.ui.cards > .red.card:hover, .ui.red.card:hover, .ui.red.cards > .card:hover {
  border-top: 2px solid #DB2828;
  box-shadow: none;
}

.ui.modal > .content > .description {
  color: black;
}

.mission-list.search {
  height: 2.71428571em;
}

.create-mission-container {
  margin-bottom: 2em;
}

.ui.create-mission-btn {
  font-family: 'Furore', 'sans-serif';
  font-size: large;
  letter-spacing: 0.1em;
}

.mission-delete > .actions > .primary {
  background-color: #db2828;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.mission-delete > .actions > .primary:hover {
  background-color: #d01919;
  color: #fff;
  text-shadow: none;
}

.mission-resolve > .actions > button {
  background-color: #db2828;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.mission-resolve > .actions > button:hover {
  background-color: #d01919;
  color: #fff;
  text-shadow: none;
}

.mission-resolve > .actions > .primary {
  background-color: #21ba45;
  color: #fff;
  text-shadow: none;
  background-image: none;
}

.mission-resolve > .actions > .primary:hover {
  background-color: #16ab39;
  color: #fff;
  text-shadow: none;
}

.ui.form .field > label {
  font-size: 1.2em;
}

.full-height {
  height: 94vh;
}

.login-container {
  margin-top: 10vh;
  background-color: white;
  border-radius: 3px;
  width: 60% !important;
  padding: 20px;
}

.login-headerText {
  font-family: 'Furore', 'sans-serif';
  color: #181818;
}

/* App CSS goes here. */

@font-face {
  font-family: Furore;
  src: url(/static/media/Furore.95a623e2.otf) format("opentype");
}
body {
  font-family: 'Furore', 'sans-serif';
  background-color: #181818;
  background-image: url(/static/media/Yela.d2939981.jpg);
  background-size: cover;
  color: white;
}
.ui.menu {
  font-family: 'Furore', 'sans-serif';
}

.item > a, .ui.menu .item {
  color: #C3C6C9;
}

.active.item > a, .ui.menu .active.item {
  color: white;
}

.ui.menu a.item:hover, .ui.menu .item > a:hover {
  color: white !important;
}

.ui.card > .content > .header, .ui.cards > .card > .content > .header {
  font-family: 'Furore', 'sans-serif';
  font-size: 1.2rem;
}

.ui.large.modal > .header {
  font-family: 'Furore', 'sans-serif';
}

.content {
  color: black;
}

.ui.modal .scrolling.content {
  margin-bottom: 1em;
}

