.full-height {
  height: 94vh;
}

.login-container {
  margin-top: 10vh;
  background-color: white;
  border-radius: 3px;
  width: 60% !important;
  padding: 20px;
}

.login-headerText {
  font-family: 'Furore', 'sans-serif';
  color: #181818;
}
