/* App CSS goes here. */

@font-face {
  font-family: Furore;
  src: url("../../fonts/Furore.otf") format("opentype");
}
body {
  font-family: 'Furore', 'sans-serif';
  background-color: #181818;
  background-image: url("../../images/Yela.jpg");
  background-size: cover;
  color: white;
}
.ui.menu {
  font-family: 'Furore', 'sans-serif';
}

.item > a, .ui.menu .item {
  color: #C3C6C9;
}

.active.item > a, .ui.menu .active.item {
  color: white;
}

.ui.menu a.item:hover, .ui.menu .item > a:hover {
  color: white !important;
}

.ui.card > .content > .header, .ui.cards > .card > .content > .header {
  font-family: 'Furore', 'sans-serif';
  font-size: 1.2rem;
}

.ui.large.modal > .header {
  font-family: 'Furore', 'sans-serif';
}

.content {
  color: black;
}

.ui.modal .scrolling.content {
  margin-bottom: 1em;
}
